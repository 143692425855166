var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('Fieldset',{attrs:{"id":"information","title":_vm.$t('Basic Information')}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Full name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Full name')},model:{value:(_vm.model.fullname),callback:function ($$v) {_vm.$set(_vm.model, "fullname", $$v)},expression:"model.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*Email:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Phone') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Phone')},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Content') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Content')},model:{value:(_vm.model.content),callback:function ($$v) {_vm.$set(_vm.model, "content", $$v)},expression:"model.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"position-sticky top"},[_c('Fieldset',{attrs:{"id":"status","title":_vm.$t('Status')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Position')+":","label-cols-md":"12"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('Position')},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}})],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }